import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';

const OverlayBody = ({ fullScreen, overlayColor, zIndex, children }) => {
    if (document) {
        return createPortal(
            <div
                data-testid='overlay'
                style={{
                    position: fullScreen ? 'fixed' : 'absolute',
                    top: '0',
                    left: '0',
                    bottom: '0',
                    right: '0',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                    backgroundColor: overlayColor,
                    zIndex: zIndex
                }}>
                {children}
            </div>,
            document.body
        );
    }
};

const Overlay = ({ children, fullScreen = true, overlayColor = 'rgb(255 255 255 / 80%)', zIndex = 100000 }) => {
    return (
        <OverlayBody
            fullScreen={fullScreen}
            overlayColor={overlayColor}
            zIndex={zIndex}>
            {children}
        </OverlayBody>
    );
};

Overlay.propTypes = {
    fullScreen: PropTypes.bool,
    overlayColor: PropTypes.string,
    zIndex: PropTypes.number,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};

Overlay.defaultProps = {
    fullScreen: true,
    overlayColor: 'rgb(255 255 255 / 80%)',
    zIndex: 100000,
    children: undefined
};

export default Overlay;
