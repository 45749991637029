import { RequirementType } from '@/features/maturity-assessment/enums';
import { CheckCircleOutlined, CloseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';

export const defaultComparer = (a, b) => {
    if (a.position !== undefined && b.position !== undefined) {
        return a.position - b.position;
    } else if (a.name !== undefined && b.name !== undefined) {
        return a.name.localeCompare(b.name);
    } else if (a.createdOn !== undefined && b.createdOn !== undefined) {
        return a.createdOn - b.createdOn;
    } else {
        return 0;
    }
};

export const updateIfNewer = (existingValue, newValue, existingTimestamp, newTimestamp) => {
    return !existingTimestamp || newTimestamp > existingTimestamp ? newValue : existingValue;
};

export const getRelatedControlUpdates = (entities, ids, propertyKey, propertyValue, selectedId, state) => {
    return ids
        .map((id) => {
            const entity = entities[id];
            if (!entity) {
                return null;
            }
            if (entity[propertyKey] === selectedId) {
                return state === true
                    ? {
                          id: entity.id,
                          changes: {
                              [propertyValue]: false
                          }
                      }
                    : {
                          id: entity.id,
                          changes: {
                              [propertyValue]: true
                          }
                      };
            }
            return null;
        })
        .filter((update) => update !== null);
};

export const mergeEvidence = (clientEvidence = [], serverEvidence = []) => {
    let mergedSupportingEvidence = [];
    if (!clientEvidence || clientEvidence.length === 0) {
        mergedSupportingEvidence = Array.isArray(serverEvidence) ? [...serverEvidence] : [];
    } else if (serverEvidence && serverEvidence.length > 0) {
        mergedSupportingEvidence =
            clientEvidence
                .map((existing) => {
                    if (!existing) {
                        return;
                    }
                    const matchingEvidenceById = serverEvidence.find((evidence) => evidence.id === existing.id);
                    if (matchingEvidenceById) {
                        return { ...existing, ...matchingEvidenceById };
                    }
                })
                ?.filter(Boolean) || [];

        for (const evidence of serverEvidence) {
            const isEvidenceInMerged = mergedSupportingEvidence.some(
                (mergedEvidence) => mergedEvidence.id === evidence.id
            );

            if (!isEvidenceInMerged) {
                mergedSupportingEvidence.push(evidence);
            }
        }
    }
    return mergedSupportingEvidence;
};

export const handleSOCData = (controlCategories, controls, controlResponses, controlMaturityStatuses) => {
    const SOCData = controlCategories.map((cat) => {
        return {
            name: cat.name,
            responses: controls
                .filter((con) => con.controlCategoryId === cat.id)
                .map((con) => controlResponses.find((resp) => resp.controlId === con.id))
        };
    });
    return controlMaturityStatuses
        .filter((status) => status.controlMaturityRatingId)
        .map((status) => {
            const ob = {};
            for (const data of SOCData) {
                const count =
                    data.responses.filter((response) => response.controlMaturityStatusId === status.id)?.length || 0;
                const totalCount = Math.max(data.responses.length, 1);
                ob[data.name] = count / totalCount;
            }

            return {
                id: status.id,
                name: status.name,
                description: status.controlMaturityRatingDescription,
                ...ob
            };
        });
};

export const handlePCIData = (controlCategories, controls, controlResponses, controlMaturityStatuses) => {
    const PCIData = controlCategories.map((cat) => {
        return {
            name: cat.identifier,
            responses: controls
                .filter((con) => con.controlCategoryId === cat.id)
                .map((con) => controlResponses.find((resp) => resp.controlId === con.id))
        };
    });
    return controlMaturityStatuses
        .filter((status) => status.controlMaturityRatingId)
        .map((status) => {
            const ob = {};
            for (const data of PCIData) {
                const count =
                    data.responses.filter((response) => response.controlMaturityStatusId === status.id)?.length || 0;
                const totalCount = Math.max(data.responses.length, 1);
                ob[data.name] = count / totalCount;
            }

            return {
                id: status.id,
                name: status.name,
                description: status.controlMaturityRatingDescription,
                ...ob
            };
        });
};

export const handleNISTData = (controlCategories, controls, controlResponses, controlMaturityStatuses) => {
    const result = [];

    const NISTData = controlCategories.map((cat) => controls.filter((con) => con.controlCategoryId === cat.id));

    const identityControls = NISTData[0];
    const protectControls = NISTData[1];
    const detectControls = NISTData[2];
    const respondControls = NISTData[3];
    const recoverControls = NISTData[4];

    const identityControlResponses = identityControls?.map((index) =>
        controlResponses.find((responses) => responses.controlId == index.id)
    );
    const protectControlResponses = protectControls?.map((index) =>
        controlResponses.find((responses) => responses.controlId == index.id)
    );
    const detectControlResponses = detectControls?.map((index) =>
        controlResponses.find((responses) => responses.controlId == index.id)
    );
    const respondControlResponses = respondControls?.map((index) =>
        controlResponses.find((responses) => responses.controlId == index.id)
    );
    const recoverControlResponses = recoverControls?.map((index) =>
        controlResponses.find((responses) => responses.controlId == index.id)
    );

    for (const controlMaturityStatus of controlMaturityStatuses) {
        if (!controlMaturityStatus.controlMaturityRatingId) {
            continue;
        }

        const identityCount =
            identityControlResponses.filter((recover) => recover.controlMaturityStatusId === controlMaturityStatus.id)
                ?.length || 0;
        const totalIdentityCount = Math.max(identityControlResponses.length, 1);

        const protectCount =
            protectControlResponses.filter((recover) => recover.controlMaturityStatusId === controlMaturityStatus.id)
                ?.length || 0;
        const totalProtectCount = Math.max(protectControlResponses.length, 1);

        const detectCount =
            detectControlResponses.filter((recover) => recover.controlMaturityStatusId === controlMaturityStatus.id)
                ?.length || 0;
        const totalDetectCount = Math.max(detectControlResponses.length, 1);

        const respondCount =
            respondControlResponses.filter((recover) => recover.controlMaturityStatusId === controlMaturityStatus.id)
                ?.length || 0;
        const totalRespondCount = Math.max(respondControlResponses.length, 1);

        const recoverCount =
            recoverControlResponses.filter((recover) => recover.controlMaturityStatusId === controlMaturityStatus.id)
                ?.length || 0;
        const totalRecoverCount = Math.max(recoverControlResponses.length, 1);

        result.push({
            id: controlMaturityStatus.id,
            name: controlMaturityStatus.name,
            description: controlMaturityStatus.controlMaturityRatingDescription,
            identityCount: identityCount / totalIdentityCount,
            protectCount: protectCount / totalProtectCount,
            detectCount: detectCount / totalDetectCount,
            respondCount: respondCount / totalRespondCount,
            recoverCount: recoverCount / totalRecoverCount
        });
    }

    return result;
};

export const handleNISTv2Data = (controlCategories, controls, controlResponses, controlMaturityStatuses) => {
    const result = [];
    const NISTData = controlCategories.map((cat) => controls.filter((con) => con.controlCategoryId === cat.id));
    const governControls = NISTData[0];
    const identityControls = NISTData[1];
    const protectControls = NISTData[2];
    const detectControls = NISTData[3];
    const respondControls = NISTData[4];
    const recoverControls = NISTData[5];

    const governControlResponses = governControls?.map((index) =>
        controlResponses.find((responses) => responses.controlId == index.id)
    );

    const identityControlResponses = identityControls?.map((index) =>
        controlResponses.find((responses) => responses.controlId == index.id)
    );
    const protectControlResponses = protectControls?.map((index) =>
        controlResponses.find((responses) => responses.controlId == index.id)
    );

    const detectControlResponses = detectControls?.map((index) =>
        controlResponses.find((responses) => responses.controlId == index.id)
    );
    const respondControlResponses = respondControls?.map((index) =>
        controlResponses.find((responses) => responses.controlId == index.id)
    );
    const recoverControlResponses = recoverControls?.map((index) =>
        controlResponses.find((responses) => responses.controlId == index.id)
    );

    for (const controlMaturityStatus of controlMaturityStatuses) {
        if (!controlMaturityStatus.controlMaturityRatingId) {
            continue;
        }

        const governCount =
            governControlResponses.filter((recover) => recover.controlMaturityStatusId === controlMaturityStatus.id)
                ?.length || 0;

        const totalGovernCount = Math.max(governControlResponses.length, 1);

        const identityCount =
            identityControlResponses.filter((recover) => recover.controlMaturityStatusId === controlMaturityStatus.id)
                ?.length || 0;

        const totalIdentityCount = Math.max(identityControlResponses.length, 1);

        const protectCount =
            protectControlResponses.filter((recover) => recover.controlMaturityStatusId === controlMaturityStatus.id)
                ?.length || 0;
        const totalProtectCount = Math.max(protectControlResponses.length, 1);

        const detectCount =
            detectControlResponses.filter((recover) => recover.controlMaturityStatusId === controlMaturityStatus.id)
                ?.length || 0;
        const totalDetectCount = Math.max(detectControlResponses.length, 1);

        const respondCount =
            respondControlResponses.filter((recover) => recover.controlMaturityStatusId === controlMaturityStatus.id)
                ?.length || 0;
        const totalRespondCount = Math.max(respondControlResponses.length, 1);

        const recoverCount =
            recoverControlResponses.filter((recover) => recover.controlMaturityStatusId === controlMaturityStatus.id)
                ?.length || 0;
        const totalRecoverCount = Math.max(recoverControlResponses.length, 1);

        result.push({
            id: controlMaturityStatus.id,
            name: controlMaturityStatus.name,
            description: controlMaturityStatus.controlMaturityRatingDescription,
            governCount: governCount / totalGovernCount,
            identityCount: identityCount / totalIdentityCount,
            protectCount: protectCount / totalProtectCount,
            detectCount: detectCount / totalDetectCount,
            respondCount: respondCount / totalRespondCount,
            recoverCount: recoverCount / totalRecoverCount
        });
    }

    return result;
};

export const handleISOData = (controls, controlResponses, controlMaturityStatuses) => {
    const result = [];

    const clauseRequirementTypeControlIds = new Set(
        controls
            .filter((control) => control.requirementTypeId === RequirementType.Clause.id)
            .map((control) => control.id)
    );

    const clauseRequirementTypeControlResponses = controlResponses.filter((response) =>
        clauseRequirementTypeControlIds.has(response.controlId)
    );

    const controlRequirementTypeControlIds = new Set(
        controls
            ?.filter((control) => control.requirementTypeId === RequirementType.Control.id)
            .map((control) => control.id)
    );
    const controlRequirementTypeControlResponses = controlResponses.filter((response) =>
        controlRequirementTypeControlIds.has(response.controlId)
    );

    for (const controlMaturityStatus of controlMaturityStatuses) {
        if (!controlMaturityStatus.controlMaturityRatingId) {
            continue;
        }

        const clauseResponsesForStatusCount =
            clauseRequirementTypeControlResponses.filter(
                (response) => response.controlMaturityStatusId === controlMaturityStatus.id
            )?.length || 0;
        const controlResponsesForStatusCount =
            controlRequirementTypeControlResponses.filter(
                (response) => response.controlMaturityStatusId === controlMaturityStatus.id
            )?.length || 0;

        const totalClauseCount = Math.max(clauseRequirementTypeControlIds.size, 1);
        const totalControlCount = Math.max(controlRequirementTypeControlIds.size, 1);

        const clausePercentage = clauseResponsesForStatusCount / totalClauseCount;
        const controlPercentage = controlResponsesForStatusCount / totalControlCount;

        result.push({
            id: controlMaturityStatus.id,
            name: controlMaturityStatus.name,
            description: controlMaturityStatus.controlMaturityRatingDescription,
            clauseCount: clausePercentage,
            controlCount: controlPercentage
        });
    }

    return result;
};

export const getRequirementTypeDisplay = (requirementTypeIds) => {
    const hasControls = requirementTypeIds.has(1);
    const hasClauses = requirementTypeIds.has(2);
    if (hasControls && hasClauses) {
        return 'Controls & Clauses';
    } else if (hasControls) {
        return 'Controls';
    } else if (hasClauses) {
        return 'Clauses';
    } else {
        return '';
    }
};

export const getStatusIcon = (isCompleted, isStarted) => {
    if (isCompleted) {
        return <CheckCircleOutlined />;
    } else if (isStarted) {
        return <PlayCircleOutlined />;
    } else {
        return <CloseCircleOutlined />;
    }
};
