import { Modal, Result } from 'antd';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import '@cyntler/react-doc-viewer/dist/index.css';
import { LoadingOutlined } from '@ant-design/icons';
import mime from 'mime';
import PropTypes from 'prop-types';
import './PreviewFileModule.less';

const ErrorComponent = (error) => {
    return (
        <div className='empty'>
            <Result
                status='error'
                fileName={error?.status === 413 ? 'Not supported' : `${error}: An unexpected error occurred`}
                subTitle={error?.status === 413 ? 'File too large for preview' : 'Please try refreshing the page'}
            />
        </div>
    );
};

const PreviewNotAvailableComponent = () => {
    return (
        <div className='empty'>
            <Result
                status='error'
                fileName='Not supported'
                subTitle='File not supported in preview mode for files that do not render by default'
            />
        </div>
    );
};

const RenderDocumentViewer = ({ url, fileName, fileType }) => {
    return (
        <DocViewer
            documents={[
                {
                    uri: url || '',
                    fileName: fileName || '',
                    fileType: fileType || undefined
                }
            ]}
            className='doc-viewer-style'
            pluginRenderers={DocViewerRenderers}
            config={{
                header: { disableHeader: true },
                pdfVerticalScrollByDefault: true,
                pdfZoom: { defaultZoom: 1, zoomJump: 0.1 },
                noRenderer: {
                    overrideComponent: <PreviewNotAvailableComponent />
                }
            }}
        />
    );
};

const PreviewFileModal = ({ open, onClose, fileName, fileType, url, assessment, rating, grade }) => {
    // eslint-disable-next-line unicorn/prefer-set-has
    const documentTypeList = [
        'application/pdf',
        'application/msword',
        'image/png',
        'image/jpeg',
        'image/gif',
        'image/bmp',
        'application/vnd.oasis.opendocument.text',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'image/jpg',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'image/tiff',
        'text/plain',
        'application/vnd.ms-excel',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ];

    if (!fileName && url) {
        const urlParts = url.split('/');
        fileName = urlParts.at(-1);
    }

    if (fileName && fileType && !fileName.includes('.')) {
        const fileExtension = mime.getExtension(fileType);
        if (fileExtension) {
            fileName = `${fileName}.${fileExtension}`;
        }
    }

    const { error, isLoading } = {
        error: undefined,
        isLoading: false
    };

    const handleClose = () => {
        if (onClose) {
            onClose();
        }
    };

    let content;
    if (isLoading) {
        content = (
            <div className='loading'>
                <LoadingOutlined />
            </div>
        );
    } else if (error) {
        content = <ErrorComponent />;
    } else if (assessment) {
        content = (
            <div className='assessment-preview'>
                <RenderDocumentViewer
                    url={url}
                    fileName={fileName}
                    fileType={fileType}
                />
                <div className='assessment-preview-info'>
                    <div className='info-title'>AI Assessor Review</div>
                    <div className='info-row'>
                        <div className='label'>Rating: </div>
                        <div className='value'>{rating} </div>
                    </div>
                    <div className='info-row'>
                        <div className='label'>Grade: </div>
                        <div className='value'>{grade} </div>
                    </div>
                    <div className='info-row'>
                        <div className='label'>Assessment Summary: </div>
                        <div className='value assessment-summary'>{assessment} </div>
                    </div>
                </div>
            </div>
        );
    } else {
        content = (
            <RenderDocumentViewer
                url={url}
                fileName={fileName}
                fileType={fileType}
            />
        );
    }

    return (
        <Modal
            title={fileName}
            open={open}
            footer={null}
            width='90vw'
            destroyOnClose
            onCancel={handleClose}
            centered>
            <div className='preview'>
                {documentTypeList.includes(fileType) ? (
                    content
                ) : (
                    <Result
                        status='error'
                        title='File not supported in preview mode for files that do not render by default.'
                    />
                )}
            </div>
        </Modal>
    );
};

PreviewFileModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    fileName: PropTypes.string,
    fileType: PropTypes.string,
    url: PropTypes.string
};

PreviewFileModal.defaultProps = {
    open: false,
    onClose: () => Promise.resolve(),
    fileName: undefined,
    fileType: undefined,
    url: undefined
};

export default PreviewFileModal;
