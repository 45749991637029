import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { default as Overlay } from './Overlay';

const LoaderComponentWithoutOverlay = ({ fullScreen, zIndex }) => {
    return (
        <div
            data-testid='loader-element'
            className='loader'
            style={{
                position: fullScreen ? 'fixed' : 'relative',
                top: '0',
                left: '0',
                bottom: '0',
                right: '0',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                zIndex: zIndex
            }}>
            <Spin
                indicator={
                    <LoadingOutlined
                        spin
                        size='large'
                    />
                }
                delay={200}
                size='large'
            />
        </div>
    );
};

const LoaderComponentWithOverlay = ({ fullScreen, overlayColor, zIndex }) => {
    return (
        <Overlay
            fullScreen={fullScreen}
            overlayColor={overlayColor}
            zIndex={zIndex}>
            <Spin
                indicator={
                    <LoadingOutlined
                        spin
                        size='large'
                    />
                }
                delay={200}
                size='large'
            />
        </Overlay>
    );
};

const LoaderComponent = ({ loading = false, fullScreen = false, overlay = true, overlayColor, zIndex }) => {
    return (
        <>
            {loading && !overlay && (
                <LoaderComponentWithoutOverlay
                    fullScreen={fullScreen}
                    zIndex={zIndex}
                />
            )}
            {loading && overlay && (
                <LoaderComponentWithOverlay
                    fullScreen={fullScreen}
                    zIndex={zIndex}
                    overlayColor={overlayColor}
                />
            )}
        </>
    );
};

LoaderComponent.propTypes = {
    loading: PropTypes.bool,
    fullScreen: PropTypes.bool,
    overlay: PropTypes.bool,
    overlayColor: PropTypes.string,
    zIndex: PropTypes.number
};

LoaderComponent.defaultProps = {
    loading: false,
    overlay: true,
    fullScreen: true,
    overlayColor: undefined,
    zIndex: 100000
};

export default LoaderComponent;
